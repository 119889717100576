alertify.set({
  buttonReverse: true,
  labels : {
    ok : 'Да',
    cancel : 'Нет'
  }
});

function initCommonScripts() {
  'use strict';

  // CONST
  window.PSWP = {
    TMPL: document.getElementById('pswp_template'),
    UI: PhotoSwipeUI_Default
  };

  var $items = $('.js-pswp-gallery-item');
  var images = [];
  var settings = {
    shareEl: false
  };

  $.each($items, function(key, value) {
    var $item = $(this);
    var img = new Image();
    var itemName = $item.data('item-name');
    var itemHref = $item.data('item-href');

    $.data(this, 'image-id', key);

    img.src = $(this).attr('href');
    img.onload = function() {
      images[key] = {
        src: img.src,
        w: this.width,
        h: this.height
      };

      if (itemName) images[key].title = itemName;
    };
  });

  $items.on('click', function(e) {
    e.preventDefault();
    settings.index = $.data(this, 'image-id');

    var pswp = new PhotoSwipe(PSWP.TMPL, PSWP.UI, images, settings);

    pswp.init();
  });

  $(':checkbox, :radio').not('.hidden').forms();

  $('input[type="tel"], .phone-input').each(function() {
    var $_this = $(this), val;
    $_this.mask('+7 (999) 999-99-99', {autoclear: false});
  });

  $(document.body)
    .on('yiiListViewUpdated', function(){
      $(':checkbox, :radio').not('.hidden').forms();
/*      var $catalog = $('.catalog');
      var scrollSpeed = Math.abs( $(window).scrollTop() ) * 0.3;
      $(window).scrollTo( $catalog, {
        duration: scrollSpeed
      });*/
    })
    .on('overlayLoaderShow', function(e, $node) {
      $node.find('.autofocus-inp').focus();
    });
}

$(function() {
  initCommonScripts();

  // OverlayLoader init
  $(document).on('click.overlay', '.js-overlay', function(e) {
    e.preventDefault();
    $.overlayLoader(true, $($(this).attr('href')));
  });

  $('body').on('click', '.vitrine .product .more-link', function(e) {
    e.preventDefault();
    var dataBlock = $(this).closest('.product').find('.product-details-inner');
    if( dataBlock.html() == '' ) {
      $.mouseLoader(true);
      var self = $(this);
      $.post(self.attr('href'), null, function (content) {
        self.closest('.product').addClass('active').siblings().removeClass('active');
        self.closest('.product').find('.product-details-inner').replaceWith('<div class="product-details-inner">' + content + '</div>');;
        $.mouseLoader(false);
      });
    }
    else
    {
      $(this).closest('.product').addClass('active').siblings().removeClass('active');
    }
  });

  $('body').on('click', '.vitrine .product .close', function(e) {
    e.preventDefault();
    $(this).closest('.product').removeClass('active');
  });

  $('body').append( $('<div id="left-off-aside"><a href="" class="go-top"><span>Наверх</span></a></div>') );

  var setOffsideWidth = function(){
    var freeSpace = ( $(window).width() - $('.wrapper').width() ) / 2;
    $('#left-off-aside').css('width', freeSpace - 10);
  };

  setOffsideWidth();

  $(window).scroll(function(){
    var scrollTop = $(window).scrollTop();
    if ( scrollTop > 300 ) {
      $('#left-off-aside').addClass('visible');
    } else {
      $('#left-off-aside').removeClass('visible');
    }
  });

  $(window).resize(function(){
    setOffsideWidth();
  });

  $('#left-off-aside').click(function(){
    $('.go-top').trigger('click');
  });

  $('.go-top').click(function(e){
    e.preventDefault();
    e.stopPropagation();
    $('body').scrollTo($('header'), 300);
  });
});